<template>
  <span class="toggle-wrapper"
        :class="{ '__active': value, '__disabled': disabled }"
        @click="toggle"
        role="checkbox"
        :aria-checked="value.toString()"
        tabindex="0"
        @keydown.space.prevent="toggle">
    <span class="toggle-background">
      {{content}}
    </span>
    <span class="toggle-indicator"></span>
  </span>
</template>

<script>
export default {
  name: 'BaseSwitch',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeContent: {
      default: 'Active',
    },
    inactiveContent: {
      default: 'Inactive',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle () {
      this.$emit('input', !this.value)
    },
  },
  computed: {
    content () {
      return this.value ? this.activeContent : this.inactiveContent
    },
  },
}
</script>

<style lang="scss" scoped>
  .toggle-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 100px;
    height: 32px;
    border-radius: 9999px;

    &.__active {
      .toggle-background {
        background-color: $color-primary;
        text-align: left;
        padding: 3px 35px 3px 3px;
      }

      .toggle-indicator {
        transform: translateX(calc(100px - 26px - 3px - 3px));
      }
    }

    &.__disabled {
      pointer-events: none;
    }
  }

  .toggle-wrapper:focus {
    outline: 0;
  }

  .toggle-background {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    height: 100%;
    width: 100%;
    background-color: $color-disabled;
    text-align: right;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 3px 3px 35px;
    transition: background-color, padding-right, padding-left .2s ease;
  }

  .toggle-indicator {
    position: absolute;
    top: 3px;
    left: 3px;
    height: 26px;
    width: 26px;
    background-color: #fff;
    border-radius: 9999px;
    transform: translateX(0);
    transition: transform .2s ease;
  }
</style>
