<template>
  <div
    v-if="icon"
    class="price-recommendation"
    :class="classList"
  >
    <svg-icon :icon-name="icon" :class-name="icon"/>

    <div class="price-recommendation--tooltip">
      <p v-if="tooltipTitle">{{tooltipTitle}}</p>
      <p>{{tooltipText}}</p>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'shared/src/components/SvgIcon'
import { BAD_PRICE, GOOD_PRICE, UNIQUE_PRICE } from '../../../helpers/consts/price-quality-statuses'

export default {
  name: 'price-recommendation',
  components: { SvgIcon },
  props: {
    value: {
      type: [String, Number],
    },
    moreBy: {
      type: [String, Number],
    },
  },
  computed: {
    icon () {
      switch (this.value) {
        case GOOD_PRICE:
          return 'good-price'
        case BAD_PRICE:
          return 'bad-price'
        case UNIQUE_PRICE:
          return 'unique-price'
        default:
          return null
      }
    },
    tooltipTitle () {
      switch (this.value) {
        case BAD_PRICE:
          return this.$t('badPriceTooltipTitle', { price: this.moreBy })
        default:
          return null
      }
    },
    tooltipText () {
      switch (this.value) {
        case GOOD_PRICE:
          return this.$t('goodPrice')
        case BAD_PRICE:
          return this.$t('badPriceTooltipText')
        case UNIQUE_PRICE:
          return this.$t('uniquePriceTooltip')
        default:
          return null
      }
    },
    classList () {
      return {
        '__bad': this.value === BAD_PRICE,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.price-recommendation {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &--tooltip {
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: auto;
    white-space: nowrap;
    max-width: 286px;
    padding: 6px 8px;
    border-radius: 3px;
    background-color: $text-gray;
    color: $color-white;
    opacity: 0.01;
    pointer-events: none;
    transition: 0.3s ease opacity;
  }

  &:hover &--tooltip {
    opacity: 1;
    pointer-events: all;
  }

  &.__bad &--tooltip {
    background-color: $color-error-secondary;
  }
}

.model-card-header__price .price-recommendation:not(:last-child) {
  margin-right: 4px;
}
</style>
