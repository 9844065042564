<template>
  <tr>
    <td>
      <ProductImage
        :url="product.images[0]"/>
    </td>
    <td>
      <Attribute
        :style="{ width: '290px'}"
        v-for="attribute in product.attributes"
        :key="attribute.id"
        :attrKey="attribute.key.name"
        :value="attribute.value.name"
      />
    </td>
    <td>
      {{ product.sku }}
    </td>
    <td class="align-right">
      <div class="edit-variation">
        <p class="edit-variation--text" @click="editStock(product.id)">
          <svg-icon icon-name="edit" class-name="edit-variation--edit-icon"/>
          {{product.stock}}

          <span
            v-if="product.stockStatusCanBeChanged === false"
            class="edit-variation--tooltip"
          >
            You can change the stock after {{ priceCanBeChangedAfter }}
          </span>
        </p>

        <div
          v-if="showEditQty"
          class="edit-variation-modal"
        >
          <InputWithButtons
            :key="Math.random()"
            :value="product.stock"
            :disabled="inputsDisabled"
            :validate="validateStock"
            :fullWidth="true"
            label="Stock"
            :required="true"
            :alwaysShowButtons="true"
            sign=""
            @decline="closeEditStock(product.id)"
            @change="onChangeStock"
          />

          <div class="edit-variation-modal--footer">
            <tooltip-icon :title="$t('changeStockNotification')"/>
            {{ $t('changeStockNotification') }}
          </div>
        </div>
      </div>
    </td>
    <td class="align-right">
      <div class="edit-variation __price">
        <p class="edit-variation--text" @click="editPrice(product.id)">
          <svg-icon icon-name="edit" class-name="edit-variation--edit-icon"/>
          {{ product.discountPrice || product.price }}

          <span
            v-if="product.priceCanBeChanged === false"
            class="edit-variation--tooltip"
          >
            You can change the price after {{ priceCanBeChangedAfter }}
          </span>
        </p>

        <price-recommendation
          :value="product.priceRecommendation.value"
          :more-by="product.priceRecommendation.moreBy"
        />

        <div
          class="edit-variation-modal"
           v-if="showEditPrice"
        >
          <update-price
            :price="product.price"
            :discount-price="product.discountPrice"
            :price-quality="product.priceRecommendation.value"
            :more-by="product.priceRecommendation.moreBy"
            @decline="closeEditPrice(product.id)"
            @change="onChangeProductPrices"
          />
        </div>
      </div>
    </td>
    <td>
      {{ product.discountPrice }}
    </td>
    <td class="align-right">
      <BaseSwitch
        :value="product.status === activeStatus"
        :disabled="product.stockStatusCanBeChanged === false || inputsDisabled"
        @input="onChangeProductStatus"
        :active-content="$t('active')"
        :inactive-content="$t('inactive')"
      />
    </td>
  </tr>
</template>

<script>
import BaseSwitch from '../forms/BaseSwitch'
import Attribute from '../layout/models/Attribute'
import InputWithButtons from '../forms/InputWithButtons'
import { PRODUCT_STATUS_ACTIVE, PRODUCT_STATUS_INACTIVE } from '../../../helpers/consts/product-statuses'
import ProductImage from '../images/ProductImage'
import SvgIcon from 'shared/src/components/SvgIcon'
import PriceRecommendation from '../icons/PriceRecommendation'
import { mapState } from 'vuex'
import TooltipIcon from '../forms/TooltipIcon'
import UpdatePrice from './UpdatePrice'
import { UNIQUE_PRICE } from '../../../helpers/consts/price-quality-statuses'

export default {
  name: 'ProductTableRow',
  components: {
    UpdatePrice,
    TooltipIcon,
    PriceRecommendation,
    SvgIcon,
    ProductImage,
    InputWithButtons,
    Attribute,
    BaseSwitch,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    inputsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showEditQtyList: [],
      showEditPriceList: [],
    }
  },
  computed: {
    ...mapState({
      overlay: state => state.ui.overlay,
    }),
    showEditQty () {
      return this.overlay && this.showEditQtyList.includes(this.product.id)
    },
    showEditPrice () {
      return this.overlay && this.showEditPriceList.includes(this.product.id)
    },
    activeStatus () {
      return PRODUCT_STATUS_ACTIVE
    },
    priceCanBeChangedAfter () {
      if (this.product.priceCanBeChangedAfter) {
        return new Date(this.product.priceCanBeChangedAfter).toTimeString().split(' ')[0]
      }
      return null
    },
    stockStatusCanBeChangedAfter () {
      if (this.product.stockStatusCanBeChangedAfter) {
        return new Date(this.product.stockStatusCanBeChangedAfter).toTimeString().split(' ')[0]
      }
      return null
    },
  },
  methods: {
    validateStock (stock) {
      const stockNumber = Number(stock)
      if (isNaN(stockNumber)) return false
      if (stockNumber < 0) return false
      return Number.isInteger(stockNumber)
    },

    onChangeStock (stock) {
      const id = this.product.id
      const payload = {
        id,
        stock: {
          price: this.product.price,
          discountPrice: this.product.discountPrice,
          status: this.product.status,
          stock,
        },
      }
      this.closeEditStock(id)

      if (stock === '0' || stock === '') {
        this.$emit('warning', {
          title: this.$t('outOfStockWarningTitle'),
          description: '',
          buttonText: this.$t('changeStockAnyWay'),
          handler: () => { return this.onChangeProduct(payload) },
        })
      } else {
        this.onChangeProduct(payload)
      }
    },

    onChangeProductPrices (payload) {
      this.closeEditPrice(this.product.id)

      const bestNewPrice = Number(payload.discountPrice || payload.price)
      const bestCurrentPrice = Number(this.product.discountPrice || this.product.price)
      const moreBy = Number(this.product.priceRecommendation.moreBy || 0)
      const nearestGoodPrice = bestCurrentPrice - moreBy
      const reduceForGoodPrice = bestNewPrice - nearestGoodPrice
      const notUniquePrice = this.product.priceRecommendation.value !== UNIQUE_PRICE

      const changeParams = {
        id: this.product.id,
        stock: {
          price: payload.price,
          discountPrice: payload.discountPrice,
          status: this.product.status,
          stock: this.product.stock,
        },
      }

      if (notUniquePrice && bestNewPrice > nearestGoodPrice) {
        this.$store.commit('showOverlay')
        this.$emit('warning', {
          title: this.$t('priceWarningTitle', { count: reduceForGoodPrice }),
          description: this.$t('priceTooltipText'),
          buttonText: this.$t('changePriceAnyWay'),
          handler: () => {
            this.onChangeProduct(changeParams)
          },
        })
      } else {
        this.onChangeProduct(changeParams)
      }
    },

    onChangeProductStatus (status) {
      const params = {
        stock: this.product.stock,
        price: this.product.price,
        discountPrice: this.product.discountPrice,
      }

      const id = this.product.id

      if (status) {
        params.status = PRODUCT_STATUS_ACTIVE
        this.onChangeProduct({
          id,
          stock: params,
        })
      } else {
        params.status = PRODUCT_STATUS_INACTIVE
        this.$emit('warning', {
          title: this.$t('statusWarningTitle'),
          description: this.$t('statusWarningDescription'),
          buttonText: this.$t('changeStatusAnyWay'),
          handler: () => {
            this.onChangeProduct({
              id,
              stock: params,
            })
          },
        })
      }
    },

    onChangeProduct (payload) {
      this.$emit('onChangeProduct', payload)
      this.$store.commit('hideOverlay')
    },

    editStock (id) {
      if (this.product.stockStatusCanBeChanged) {
        this.$store.dispatch('showScrollableOverlay')
        this.showEditQtyList.push(id)
      }
    },

    closeEditStock (id) {
      this.$store.dispatch('hideScrollableOverlay')
      this.showEditQtyList = this.showEditQtyList.filter(item => item !== id)
    },

    editPrice (id) {
      if (this.product.priceCanBeChanged) {
        this.$store.dispatch('showScrollableOverlay')
        this.showEditPriceList.push(id)
      }
    },

    closeEditPrice (id) {
      this.$store.dispatch('hideScrollableOverlay')
      this.showEditPriceList = this.showEditPriceList.filter(item => item !== id)
    },
  },
  watch: {
    overlay (overlay) {
      if (overlay === false) {
        this.showEditQtyList = []
        this.showEditPriceList = []
      }
    },
  },
}
</script>

<style scoped lang="scss">
td {
  padding: 12px 8px;
  border: solid $color-line;
  border-width: 0 1px;
  vertical-align: middle;
}

.edit-variation {
  position: relative;

  &--text {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
    position: relative;

    .svg-icon {
      margin-right: 8px;
    }
  }

  &--tooltip {
    position: absolute;
    top: calc(100% + 8px);
    left: -50%;
    white-space: nowrap;
    font-size: 12px;
    color: $text-gray;
    display: inline-block;
    padding: 9px 17px;
    background-color: $color-white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid $color-line;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s ease opacity;
  }

  &--text:hover &--tooltip {
    opacity: 1;
  }

  &.__price {
    display: inline-flex;
    align-items: center;
  }
}

.edit-variation-modal {
  position: absolute;
  top: calc(100% + 8px);
  left: calc(50% - 170px);
  width: 340px;
  background-color: #fff;
  padding: 12px;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  text-align: left;

  &--footer {
    display: flex;
    font-size: 12px;
    color: $text-gray;
  }
}
</style>
