<template>
  <div class="product-table-container">
    <table class="product-table">
      <thead>
        <tr>
          <th class="product-table--photo">
            {{ $t('photo') }}
          </th>
          <th class="product-table--variations">
            {{ $t('variations') }}
          </th>
          <th class="product-table--sku">
            {{ $t('listSKU') }}
          </th>
          <th class="product-table--stock align-right">
            {{ $t('listQty') }}
          </th>
          <th class="product-table--price align-right">
            {{ $t('modelPrice') }}
          </th>
          <th class="product-table--discount align-right">
            {{ $t('discountPrice') }}
          </th>
          <th class="product-table--approval-statuses align-right">
            {{ $t('orderStatus') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template
          :key="product.id"
          v-for="product in data"
        >
          <product-table-row
            :product="product"
            :inputsDisabled="inputsDisabled"
            @onChangeProduct="onChangeProduct"
            @warning="onWarning"
          />
        </template>
      </tbody>
    </table>
    <warning-modal
      v-if="showWarningModal"
      :title="warningModalContent.title"
      :description="warningModalContent.description"
      :handle-button-text="warningModalContent.buttonText"
      @cancel="cancelEdit"
      @handle="warningModalContent.handler"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductTableRow from './ProductTableRow'
import WarningModal from '../modals/WarningModal'

export default {
  name: 'ProductTable',
  components: {
    WarningModal,
    ProductTableRow,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    inputsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showWarningModal: false,
      warningModalContent: {
        handler: () => {},
      },
    }
  },
  computed: {
    ...mapState({
      overlay: state => state.ui.overlay,
    }),
  },
  methods: {
    onChangeProduct (payload) {
      this.$emit('onChangeProduct', payload)
    },
    onWarning (payload) {
      this.$store.commit('showOverlay')
      this.showWarningModal = true
      this.warningModalContent = payload
    },
    cancelEdit () {
      this.$store.commit('hideOverlay')
    },
  },
  watch: {
    overlay (overlay) {
      if (overlay === false) {
        this.showWarningModal = false
        this.warningModalContent = {}
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.product-table {
  width: 100%;
  border: solid $color-line;
  border-width: 1px 0;
  margin: 20px 0 16px 0;
  text-align: left;

  th {
    line-height: 16px;
    padding: 12px 8px;
    font-weight: 500;
    color: #000;
    border-bottom: 1px solid $color-line;
    border-left: 1px solid $color-line;
    background-color: $color-background;

    &:last-child {
      border-right: 1px solid $color-line;
    }
  }

  &--photo {
    width: $photo-width;
  }

  &--variations {
    width: $variations-width;
  }

  &--sku {
    width: $sku-width;
  }

  &--stock {
    width: $stock-width;
  }

  &--price {
    width: $price-width;
  }

  &--approval-statuses {
    width: $approval-status-width;
  }
}
</style>
