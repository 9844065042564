<template>
  <div
    class="input-with-buttons"
    @focusin="focused"
  >
    <div class="input-with-buttons--label" v-if="label">
      <span class="input-with-buttons--label-required" v-if="required">* </span>{{label}}
    </div>

    <BaseInputNumber
      :value="myValue"
      :disabled="disabled"
      :sign="sign"
      :invalid="!isValid"
      :fullWidth="fullWidth"
      @input="onInput"
      @onFocusOut="onFocusOut"
    />

    <div class="input-with-buttons--buttons" v-if="showButtons">
      <ChangeDataButton
          v-show="isValid"
          icon="checkmark"
          @click="accept"
          :svgStyle="{ width: '18px', height: '10px', }"
      />
      <ChangeDataButton icon="close-light" @click="decline"/>
    </div>
  </div>
</template>

<script>
import BaseInputNumber from './BaseInputNumber'
import ChangeDataButton from '../buttons/ChangeDataButton'

export default {
  name: 'InputWithButtons',
  components: { ChangeDataButton, BaseInputNumber },
  props: {
    value: {
      type: [ String, Number ],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sign: {
      type: String,
    },
    validate: {
      type: Function,
      default: () => { return true },
    },
    fullWidth: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    alwaysShowButtons: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      initialValue: '',
      myValue: '',
      acceptTimeout: null,
      isValid: true,
      isFocused: false,
    }
  },

  computed: {
    showButtons () {
      if (this.alwaysShowButtons) return true
      return this.initialValue !== this.myValue && this.isFocused
    },
    changed () {
      return this.initialValue !== this.myValue
    },
  },

  methods: {
    focused () {
      this.isFocused = true
    },

    onInput (value) {
      this.isValid = this.validate(value)
      this.myValue = value
    },

    accept () {
      if (this.isValid && this.changed) {
        this.initialValue = this.myValue
        this.$emit('change', this.myValue)
      }
    },

    decline () {
      clearTimeout(this.acceptTimeout)
      this.myValue = this.initialValue
      this.$emit('decline')
    },

    onFocusOut () {
      this.acceptTimeout = setTimeout(() => {
        clearTimeout(this.acceptTimeout)
        this.accept()
        this.isFocused = false
      }, 100)
    },

    setValues () {
      this.initialValue = this.value
      this.myValue = this.value
    },
  },

  mounted () {
    this.setValues()
  },

  beforeDestroy () {
    clearTimeout(this.acceptTimeout)
    this.isFocused = false
  },
}
</script>

<style lang="scss" scoped>
  .input-with-buttons {
    position: relative;
    margin-bottom: 60px;

    &--buttons {
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      right: 0;
      top: calc(100% + 8px);

      > * {
        margin-left: 8px;
      }

      .change-data-button-icon {
        fill: $color-error-secondary;
        color: transparent;
        stroke: none;
      }
    }

    &--label {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &--label-required {
      color: $color-error;
    }
  }
</style>
