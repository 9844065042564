<template>
  <div class="update-price">
    <base-input-number
      :value="priceValue"
      :invalid="!priceValueValid"
      :fullWidth="true"
      :required="true"
      label="Price"
      @input="onInputPrice"
    />
    <base-input-number
      :value="discountPriceValue"
      :invalid="!discountPriceValueValid"
      :fullWidth="true"
      label="Discount price"
      @input="onInputDiscountPrice"
    />

    <div class="update-price--tip" :class="priceTipClasses">
      {{ priceTip }}
    </div>

    <div class="update-price--buttons">
      <ChangeDataButton
        :class="'update-price--button'"
        v-show="priceValueValid && discountPriceValueValid"
        icon="checkmark"
        @click="accept"
        :svgStyle="{ width: '18px', height: '10px', }"
      />
      <ChangeDataButton :class="'update-price--button'" icon="close-light" @click="decline"/>
    </div>

    <div class="update-price--footer">
      <tooltip-icon :title="$t('changePriceNotification')"/>
      {{ $t('changePriceNotification') }}
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../forms/TooltipIcon'
import BaseInputNumber from '../forms/BaseInputNumber'
import ChangeDataButton from '../buttons/ChangeDataButton'
import { BAD_PRICE, GOOD_PRICE, UNIQUE_PRICE } from '../../../helpers/consts/price-quality-statuses'
export default {
  name: 'UpdatePrice',
  components: { ChangeDataButton, BaseInputNumber, TooltipIcon },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    price: {
      type: [String, Number],
    },
    discountPrice: {
      type: [String, Number],
    },
    priceQuality: {
      type: String,
    },
    moreBy: {
      type: [String, Number],
    },
  },
  data () {
    return {
      priceValue: null,
      discountPriceValue: null,
      priceValueValid: true,
      discountPriceValueValid: true,
    }
  },
  computed: {
    changed () {
      return this.priceValue !== this.price || this.discountPriceValue !== this.discountPrice
    },
    priceTip () {
      switch (this.priceQuality) {
        case GOOD_PRICE:
          return this.$t('normalPrice')
        case BAD_PRICE:
          return this.$t('badPriceTooltipTitle', { price: this.moreBy })
        case UNIQUE_PRICE:
          return this.$t('uniquePriceTooltip')
        default:
          return null
      }
    },
    priceTipClasses () {
      return {
        '__bad': this.priceQuality === BAD_PRICE,
      }
    },
  },
  methods: {
    onInputPrice (value) {
      this.priceValueValid = this.validatePrice(value)
      this.priceValue = value
      this.discountPriceValueValid = this.validateDiscountPrice(this.discountPriceValue)
    },
    onInputDiscountPrice (value) {
      this.discountPriceValueValid = this.validateDiscountPrice(value)
      this.discountPriceValue = value
      this.priceValueValid = this.validatePrice(this.priceValue)
    },
    validatePrice (price) {
      const priceNumber = Number(price)
      if (isNaN(priceNumber)) return false
      return priceNumber > 0
    },
    validateDiscountPrice (discountPrice) {
      if (!discountPrice) {
        return true
      }
      const discountPriceNumber = Number(discountPrice)
      const priceValueNumber = Number(this.priceValue)
      if (isNaN(discountPriceNumber)) return false
      if (isNaN(priceValueNumber)) return false
      if (discountPriceNumber >= priceValueNumber) return false
      return discountPriceNumber > 0
    },
    onFocusOut () {
      this.acceptTimeout = setTimeout(() => {
        clearTimeout(this.acceptTimeout)
        this.accept()
        this.isFocused = false
      }, 100)
    },
    accept () {
      if (this.priceValueValid && this.discountPriceValueValid && this.changed) {
        this.$emit('change', {
          price: this.priceValue,
          discountPrice: this.discountPriceValue,
        })
      }
    },
    decline () {
      this.priceValue = this.price
      this.discountPriceValue = this.discountPrice
      this.$emit('decline')
    },
  },
  watch: {
    price: {
      immediate: true,
      handler (price) {
        this.onInputPrice(price)
      },
    },
    discountPrice: {
      immediate: true,
      handler (discountPrice) {
        this.onInputDiscountPrice(discountPrice)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.update-price {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &--footer,
  &--tip,
  &--buttons {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &--tip {
    margin: 8px 0;
    color: $color-primary;
    font-size: 12px;

    &.__bad {
      color: $color-error-secondary;
    }
  }

  &--buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }

  &--button {
    margin-left: 8px;
  }

  &--footer {
    display: flex;
    font-size: 12px;
    color: $text-gray;
  }
}
</style>
