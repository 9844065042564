<template>
  <modal-on-overlay
    :fullWidthHeader="true"
    class="warning-modal"
    @close="cancel"
  >
    <template #header>
      <img
        class="warning-modal--icon"
        :src="require('shared/src/assets/error-light.svg')"
      />
      <p>{{title}}</p>
    </template>
    <template #body>
      <p>{{description}}</p>
    </template>
    <template #buttons>
      <simple-button :transparent="true" :value="cancelButtonText || $t('cancel')" @click="cancel"/>
      <warn-button :value="handleButtonText" @click="handle"/>
    </template>
  </modal-on-overlay>
</template>

<script>
import ModalOnOverlay from './ModalOnOverlay'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import WarnButton from '../buttons/WarnButton'

export default {
  name: 'WarningModal',
  components: {
    ModalOnOverlay,
    SimpleButton,
    WarnButton,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    handleButtonText: {
      type: String,
    },
    cancelButtonText: {
      type: String,
    },
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    handle () {
      this.$emit('handle')
    },
  },
}
</script>

<style scoped lang="scss">
.warning-modal {
  text-align: center;

  &--icon {
    margin: 0 auto 20px;
  }
}
</style>
