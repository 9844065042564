<template>
  <button
    @click="$emit('click')"
    class="change-data-button"
  >
    <svg-icon
      :icon-name="icon"
      :style="svgStyle"
      class-name="change-data-button-icon"
    />
  </button>
</template>

<script>

export default {
  name: 'ChangeDataButton',
  props: {
    icon: {
      type: String,
      default: 'checkmark',
    },
    svgStyle: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
  .change-data-button {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border: 1px solid $border-color;
    transition: background-color, border-color 0.2s ease;
    cursor: pointer;

    &:hover {
      background-color: $color-hover;
      border-color: $color-line;
    }

    &.__disabled {
      background-color: $color-disabled;
      border-color: $color-line;
      cursor: default;
      pointer-events: none;
    }

    &.__disabled:hover {
      background-color: $color-disabled;
    }

    &-icon {
      fill: none;
      stroke: $color-gray-icon;
      stroke-width: 2px;
    }
  }
</style>
