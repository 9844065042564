<template>
  <div class="base-input-number">
    <div class="base-input-number--label" v-if="label">
      <span v-if="required" class="base-input-number--required">*</span>{{label}}
    </div>
    <base-input
      :value="formattedValue"
      :sign="sign"
      :disabled="disabled"
      :invalid="invalid"
      :fullWidth="fullWidth"
      @input="onInput"
      focusOutEvent="onFocusOut"
      @onFocusOut="$emit('onFocusOut')"
    />
  </div>
</template>

<script>
import BaseInput from 'smarket-ui/src/components/forms/BaseInput'

export default {
  name: 'BaseInputNumber',
  components: { BaseInput },
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },

    separator: {
      type: String,
      default: '\xa0',
    },

    sign: {
      type: String,
      default: '$',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    invalid: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    stringValue () {
      return `${this.value}`
    },

    formattedValue () {
      return this.format(this.stringValue)
    },
  },

  methods: {
    format (value) {
      const [ integerPart, fractionalPart ] = value.split(/[.,]/)
      const hasMinus = integerPart < 0
      const formatted = integerPart
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, this.separator)
      return `${hasMinus ? '-' : ''}${formatted}${fractionalPart ? '.' + fractionalPart : ''}`
    },

    unFormat (value) {
      const [ integerPart, fractionalPart ] = value.split(/[.,]/)
      const regexp = new RegExp(this.separator, 'g')
      const unFormatted = integerPart
        .replace(regexp, '')
      return fractionalPart ? `${unFormatted}.${fractionalPart}` : unFormatted
    },

    onInput (value) {
      this.$emit('input', this.unFormat(value))
    },
  },
}
</script>

<style scoped lang="scss">
.base-input-number {
  &--label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &--required {
    color: $color-error;
    margin-right: 4px;
  }
}
</style>
